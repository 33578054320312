<template>
    <div>
        <section class="py-5" v-if="hotel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <!-- <p class="subtitle text-primary">¡Excelente elección ya casi terminamos!</p> -->
                        <div class="text-block" style="padding-top:0;">
                            <div class="alert alert-info text-sm mb-3" style="color:#565656;">
                                <div class="media align-items-center">
                                    <div class="media-body"><strong>{{ __('You are one step away from confirming your reservation') }}.</strong> </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-block">
                            <form action="/process-payment" class="js-validate" novalidate="novalidate" id="payment-form" ref="paymentForm" method="POST">
                                <csrf-token></csrf-token>
                                  <input type="hidden" name="payment_id" :value="payment_id">
                                <input type="hidden" name="total" :value="total">
                                <input type="hidden" name="hotel_price" :value="total">
                                <input type="hidden" name="hotel_net" :value="total"> 
                                <input type="hidden" name="regular_price" :value="total">
                                <input type="hidden" name="description" :value="'Reservación: ' + hotel.Resort_name">
                                <input type="hidden" name="client_id" :value="clientId">
                                <input type="hidden" name="reservation_id" :value="reservationId">
                                <input type="hidden" name="coupon" :value="coupon">
                                <input type="hidden" name="use_coupon" :value="coupon_valid">
                                <input type="hidden" name="discount_coupon" :value="discount_coupon">
                                <input type="hidden" name="first_reservation_discount" :value="firstReservationDiscount">
                                <input type="hidden" name="amount_type" :value="amount_type">
                                <input type="hidden" name="city_id" :value="city">
                                <input type="hidden" name="hotel" value="1">
                                <input type="hidden" name="hotel_code" :value="hotel.Resort_ID">
                                <input type="hidden" name="hotel_name" :value="hotel.Resort_name">
                                <input type="hidden" name="hotel_address" :value="hotel.Address1">
                                <input type="hidden" name="hotel_latitude" :value="hotel.GPS_Lat">
                                <input type="hidden" name="hotel_longitude" :value="hotel.GPS_Long">
                                <input type="hidden" name="hotel_main_picture" :value="hotel.ImageProfile">
                                <input type="hidden" name="hotel_phone_number" :value="hotel.ResortPhone">
                                <input type="hidden" name="hotel_description" :value="hotel.ResortHighLights">
                                <input type="hidden" name="hotel_inventory_id" :value="hotel.InventoryID">
                                <input type="hidden" name="hotel_inv_type" :value="hotel.WeekType">
                                <input type="hidden" name="hotel_start_datetime" :value="hotel.CheckInDate">
                                <input type="hidden" name="hotel_end_datetime" :value="hotel.CheckOutDate">
                                <input type="hidden" name="hotel_max_occupancy" :value="hotel.MaxOccupancy">
                                <input type="hidden" name="points_value" :value="pointsValue">
                                <input type="hidden" name="points" :value="points">
                                <input type="hidden" name="TravelerCity" :value="TravelerCity">
                                <input type="hidden" name="TravelerAddress" :value="TravelerAddress">
                                <input type="hidden" name="TravelerState" :value="TravelerState">
                                <input type="hidden" name="TravelerZipCode" :value="TravelerZipCode">
                                <input type="hidden" name="TravelerCountry" :value="TravelerCountry">
                                <input type="hidden" name="clientReservation" :value="clientReservation">
                                <input type="hidden" name="TravelerName" :value="TravelerName">
                                <input type="hidden" name="TravelerLastName" :value="TravelerLastName">
                                <input type="hidden" name="IDINVRESERVACIONES" :value="IDINVRESERVACIONES">
                                <input type="hidden" name="IDRESERVATIONINTERNA" :value="IDRESERVATIONINTERNA">

                                <h3 class="subtitle" style="color: #565656;">{{ __('Finalize your book') }}</h3>
                                <!-- <h1 class="h4 mb-4"> {{ __('Payment methods') }} <span class="text-muted float-right"></span></h1> -->
                                <p class="h6 mb-3" v-if="canPayAPortion">¿{{ __('How do you want to continue') }}?</p>
                                <div class="row mb-3" v-if="canPayAPortion">
                                    <div class="col-md-6">
                                        <div class="border p-2">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-control-input" v-model="amount_type" id="complete" name="amount_type" value="complete">
                                                <label class="custom-control-label" for="complete">
                                                    {{ __('100% payment') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!--div class="col-md-6" v-if="this.typediscountmembership != 'fixed'">
                                        <div class="border p-2">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-control-input" v-model="amount_type" id="partial" name="amount_type" value="partial">
                                                <label class="custom-control-label" for="partial">
                                                    {{ __('60% payment') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div-->
                                </div>
                            </form>

                            <stripe-checkout 
                                :idreservation="IDRESERVATIONINTERNA"
                                :total="toPay" 
                                :currency="currency.code"
                                :lang="lang"
                                @requesting-payment="is_loading = true"
                                @payment-successfully="paymentSuccessfully"
                                @payment-failure="paymentFailure"
                            ></stripe-checkout>
                        </div>

                    </div>
                    <div class="col-lg-5 pl-xl-5">
                        <div class="card border-0 shadow ">
                            <div class="card-body p-4">
                                <div class="text-block pb-3">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <img
                                                v-bind:src="'https://www.rci.com/static/Resorts/Assets/'+hotel.Resort_ID+'E01.jpg'"
                                                class="rounded w-100" style="height: 200px;">
                                            <h4> <a href="detail-rooms.html" class="text-reset">{{hotel.ResortName}}</a>
                                            </h4>
                                            <p class="text-muted text-sm mb-0">{{hotel.UnitType}} {{ __('in') }}
                                                {{hotel.ResortCity}}, {{hotel.ResortCountry}}</p>
                                            <div class="mt-n1"><i class="fa fa-xs fa-star" style="color: #565656;"></i><i
                                                    class="fa fa-xs fa-star" style="color: #565656;"></i><i
                                                    class="fa fa-xs fa-star" style="color: #565656;"></i><i
                                                    class="fa fa-xs fa-star" style="color: #565656;"></i><i
                                                    class="fa fa-xs fa-star text-gray-200"></i>
                                            </div>
                                            <img src="/assets/cheque_2.png"> {{hotel.Description_bedroom}}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block py-3">
                                    <ul class="list-unstyled mb-3">
                                        <li ><i
                                                class="fas fa-users fa-fw mr-2" style="color: #565656;"></i>{{hotel.MaxOccupancy}}
                                            {{ __('People') }}.</li>
                                        <li>
                                            <i class="fas fa-moon mr-2" style="color: #565656;"></i>
                                            {{hotel.noches}} {{ __('Nights') }}
                                        </li>
                                    </ul>
                                    <div class="text-center border py-2 text-weight-500">
                                     <p>
                                            Check In: <br>
                                            {{fnformatDate(hotel.CheckInDate)}}
                                        </p>
                                        <p>
                                            Check Out: <br>
                                            {{fnformatDate(hotel.CheckOutDate)}}
                                        </p>
                                        <p>
                                            ${{ hotel.priceDlls }} USD
                                        </p>
                                    </div>
                                </div>
                                <div v-if="this.hotel.InvTypeInDetail2 == 'Mandatory All Inclusive Weeks' || this.hotel.InvTypeInDetail2 == 'Mandatory All Inclusive 3/4'" class="text-center" style="margin-bottom: 20px;margin-top: 30px;">
                                    <small style="text-align: end;color: #7A7A7A;">{{ __('Mandatory All Inclusive Fees must be paid upon arrival to the host resort') }}</small>
                                    <table class="table text-sm mb-0" style="background-color: #00aed214;">
                                        <tbody>
                                            <tr>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('From to') }}</th>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('Fees') }}</th>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('Unit Type') }}</th>
                                            </tr>
                                            <tr v-for="option in fee">
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">{{ option[0] }}</th>
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">Fee {{ option[3] }}: {{option[1]}} {{option[2]}} per {{option[4]}} {{option[5]}}</th>
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">{{ option[6] }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import differenceInDays from 'date-fns/differenceInDays';
    import parse from 'date-fns/parse';
    import { is_string } from "./../util";
    import round from 'lodash/round';

     export default {
        props: ['travelcash','now', 'years', 'reservationFailedId', 'couponRegister', 'reservationsCount', 'firstReservationDiscount', 'currency', 'mexicanCurrencyExchageRate','discountUser', 'minimumPayment', 'points', 'pointsValue', 'lang'],
        data() {
            return {
                hotel: null,
                name: '',
                last_name: '',
                currencyInfo:'',
                email: '',
                fee:[],
                cell_phone: '',
                phone_number: '',
                clientId: 0,
                reservationId: null,
                first_booking: false,
                method: 'card',
                amount_type: 'complete',
                coupon: '',
                coupon_valid: false,
                discount_coupon: 0,
                is_coupon_loading:false,
                city: null,
                TravelerCity: '',
                TravelerAddress: '',
                TravelerState: '',
                TravelerZipCode: '',
                TravelerCountry: '',
                // currency: '',
                couponMessage: null,
                errorMessage: null,
                is_loading: false,
                payment_id: null,
                currentSubscription:'',
                typediscountmembership:'',
                clientReservation:'',
                TravelerName:'',
                TravelerLastName:'',
                IDINVRESERVACIONES:'',
                IDRESERVATIONINTERNA:''
            }
        },
        computed: {
            applyFirstBookingDiscount() {
                return this.first_booking && this.hotel.isLoggedIn;
            },
            canPayAPortion() {
                return differenceInDays(parse(this.hotel.CheckInDate, 'yyyy-MM-dd', new Date()), parse(this.now, 'yyyy-MM-dd', new Date())) > 30 && this.hotel.isLoggedIn;
            },
            canPayWithMultipleOptions() {
                return this.totalInMexicanCurrency < 10000;
            },
            toPay() {
                return this.total;
            },
            toPayInfo() {
                return this.hotel.priceDlls;
            },
            total() {
                    this.currencyInfo = this.currency.code;
                    this.currency.code = 'USD';
                    return round(this.hotel.priceDlls - this.firstReservationDiscount - this.pointsValue, 2);
               
            },
            totalInMexicanCurrency() {
                let coupon = this.currency.code !== this.mexicanCurrencyExchageRate.code ? 
                    this.discount_coupon * parseFloat(this.mexicanCurrencyExchageRate.value) : 
                    this.discount_coupon;

                return (parseFloat(this.mexicanCurrencyExchageRate.value) * this.hotel.BasePublicPrice - coupon)
            },
            hotelAddress() {
                let address = ''; 

                if (is_string(this.hotel.ResortAddress1)) {
                    address += this.hotel.ResortAddress1;
                }
                
                if (is_string(this.hotel.ResortAddress2)) {
                    address += this.hotel.ResortAddress2;
                }
                if (is_string(this.hotel.ResortAddress3)) {
                    address += this.hotel.ResortAddress3;
                }

                return address;
            }
        },
        mounted() {
            /*if(localStorage.getItem('currentSubscription') !== ''){
                this.currentSubscription = JSON.parse(localStorage.getItem('currentSubscription'));
                this.typediscountmembership = JSON.parse(localStorage.getItem('typediscountmembership'));
            }*/

            this.TravelerName = localStorage.getItem('TravelerName');
            this.TravelerLastName = localStorage.getItem('TravelerLastName');
            this.IDINVRESERVACIONES = localStorage.getItem('IDINVRESERVACIONES');
            this.IDRESERVATIONINTERNA = localStorage.getItem('IDRESERVATIONINTERNA');

            this.first_booking = this.reservationsCount == 0;
            this.hotel = JSON.parse(localStorage.getItem('selectedHotel'));
            
            //this.hotel = JSON.parse(this.hotel);
            if(this.hotel.InvTypeInDetail2 == "Mandatory All Inclusive Weeks" || this.hotel.InvTypeInDetail2 == "Mandatory All Inclusive 3/4"){
                var fee = this.hotel.AIFees.split('<Fee>');

                for(var i = 0; i < fee.length; i++){
                    var val = fee[i].replace('</Fee>', '');
                    var val = val.split('|');
                    if(val.length > 1){
                        this.fee.push(val);
                    }
                }
            }

            // this.currency = localStorage.getItem('currency') || 'USD';
            this.city = localStorage.getItem('city');

            this.clientReservation = localStorage.getItem('clientReservation');
            
            if(localStorage.getItem('TravelerCity') !== null) {
                this.TravelerCity = localStorage.getItem('TravelerCity');
            }
            if(localStorage.getItem('TravelerAddress') !== null) {
                this.TravelerAddress = localStorage.getItem('TravelerAddress');
            }
            if(localStorage.getItem('TravelerState') !== null) {
                this.TravelerState = localStorage.getItem('TravelerState');
            }
            if(localStorage.getItem('TravelerZipCode') !== null) {
                this.TravelerZipCode = localStorage.getItem('TravelerZipCode');
            }
            if(localStorage.getItem('TravelerCountry') !== null) {
                this.TravelerCountry = localStorage.getItem('TravelerCountry');
            }

            
            if (this.reservationFailedId) {
                this.reservationId = this.reservationFailedId;
            } else if (localStorage.getItem('reservation_id') !== null) {
                this.reservationId = localStorage.getItem('reservation_id');
            } 

            if (this.couponRegister) {
                this.coupon = this.couponRegister;
                this.checkIfCouponIsValid();
            }

            if(localStorage.getItem('client_id') !== null) {
                this.clientId = localStorage.getItem('client_id');
            }

            if(this.clientId == 0) {
                this.clientId = localStorage.getItem('u');
            }

            this.checkReservationsByClientId()
        },
        methods: {
            fnformatDate(value) {
                var months = ['', "Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
                var [year, month, day] = value.split('-');
                day = day.split(' ');
                var dd = this.addLeadingZeros(day[0], 2) + " / " + months[parseInt(month)] + " / " + this.addLeadingZeros(year, 4);
                return dd;
            },
            addLeadingZeros(number, targetLength) {
                var sign = number < 0 ? '-' : ''
                var output = Math.abs(number).toString()
                while (output.length < targetLength) {
                    output = '0' + output
                }
                return sign + output
            },
            paymentSuccessfully(paymentResult) {
                this.payment_id = paymentResult.id;
                this.$nextTick(() => {
                    this.$refs.paymentForm.submit();
                })
            },
            paymentFailure(paymentResult){
                this.is_loading = false;
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            formatDate(value) {
                var d = new Date(value);
                var dias = -14;
                d.setDate(d.getDate() + dias);

                var year = '';
                var month = '';
                var day = '';

                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

            return [year, month, day].join('-');
            },
            async checkReservationsByClientId() {
               let { data } = await axios.get(`/client/reservations/${this.clientId}`);
            },
            async checkIfCouponIsValid() {
                this.is_coupon_loading = true
                let { data } = await axios.get(`/coupon/${this.coupon}`);

                if(data.is_valid) {
                    this.coupon = data.coupon.code
                    
                    this.discount_coupon = data.coupon.fixed_rate == 1 ?this.hotel.RegularPrice - data.coupon.value : data.coupon.value
                    
                    this.valid_coupon = true
                }
                this.is_coupon_loading = false

                this.couponMessage = data.message || null;
            },
        }
    }

</script>

<style scoped>
.check-options .form-check-input {
    opacity: 0;
}

.form-check-input:checked + .form-check-label {
    
    background-color: #4E66F8;
    color: white;
    cursor: pointer;
}
</style>
