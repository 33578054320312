<template>
    <div>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 border-left-2">
                        <div class="alert alert-info mb-3" style="color: #565656;">
                            <svg class="bi bi-alert-triangle" width="1rem" height="1rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M9.938 4.016a.146.146 0 00-.054.057L3.027 15.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L10.12 4.073a.146.146 0 00-.054-.057.13.13 0 00-.063-.016.13.13 0 00-.064.016zm1.043-.45a1.13 1.13 0 00-1.96 0L2.166 15.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L10.982 3.566z" clip-rule="evenodd"/>
                                <rect width="2" height="2" x="9.002" y="13" rx="1"/>
                                <path d="M9.1 7.995a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0L9.1 7.995z"/>
                            </svg>
                            {{ __('This accommodation is seen many times a day') }}.
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto mb-2 pl-4" style="color: #565656"><h3>{{ __('General data') }}</h3></div>
                            <div class="col-auto text-muted px-4"><h3>{{ __('Step 1') }}</h3></div>
                        </div>
                        <!-- <h3 class="mb-2 text-primary col-12"> {{ __('General data') }} <span class="text-muted float-right">{{ __('Step 1') }}</span></h3> -->
                        <div class="bg-white p-3 shadow">
                            <form novalidate="novalidate">
                                <div class="mb-4">
                                    <h5>{{ __('Reservation holder') }}</h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Full name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="name"
                                                    required="" 
                                                    :placeholder="__('Full name')"
                                                    aria-label="Nombre completo" 
                                                    v-model="name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Last Name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="last_name"
                                                    required="" 
                                                    :placeholder="__('Last Name')" 
                                                    aria-label="Apellidos"
                                                    v-model="last_name">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label>{{ __('Email') }}</label>
                                    <div class="input-group input-group form">
                                        <input 
                                            type="email" 
                                            class="form-control form__input" 
                                            name="email" 
                                            required=""
                                            :placeholder="__('Email')" 
                                            aria-label="Correo electrónico"
                                            v-model="email">
                                    </div>
                                </div>

                                <p v-if="errors.length">
                                    <!-- <b>Por favor, corrija el(los) siguiente(s) error(es):</b> -->
                                    <ul>
                                        <li v-for="error in errors">{{ error }}</li>
                                    </ul>
                                </p>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="international_code" class="form-label">{{ __('Country') }}</label>
                                            <select
                                            name="international_code"
                                            id="international_code"
                                            required
                                            class="form-control form__input" 
                                            v-model="international_code"
                                            >
                                            <option :value="null">{{ __('Choose a option') }}</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Phone number') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="phone_number"
                                                    required="" 
                                                    :placeholder="__('Phone number')" 
                                                    aria-label="Teléfono"
                                                    v-model="phone_number">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Address') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="address"
                                                    required="" 
                                                    :placeholder="__('Address')" 
                                                    aria-label="Address"
                                                    v-model="address"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Zip code') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="number" 
                                                    class="form-control form__input" 
                                                    name="zip_code"
                                                    required="" 
                                                    placeholder="77711" 
                                                    aria-label="Código postal"
                                                    v-model="zipCode"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Country') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select
                                                    class="form-control form__input" 
                                                    name="country"
                                                    required="" 
                                                    aria-label="Country"
                                                    v-model="country"
                                                >
                                                    <option :value="null">{{ __('Choose a option') }}</option>
                                                    <option :value="121">MEXICO</option>
                                                    <option :value="195">USA</option>
                                                    <option :value="32">CANADA</option>
                                                    <option :value="40">COLOMBIA</option>
                                                    <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                                      {{ option.text }}
                                                    </option>
                                                    
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="stateOptions.length > 0">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('State') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select 
                                                    class="form-control form__input" 
                                                    name="state"
                                                    :placeholder="__('State')" 
                                                    aria-label="state"
                                                    v-model="state"
                                                >
                                                    <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('City') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    class="form-control form__input" 
                                                    name="city_id"
                                                    required="" 
                                                    :placeholder="__('City')" 
                                                    aria-label="City"
                                                    v-model="city"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-check text-right mt-3 mb-3">
                                    <input type="checkbox" class="form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
                                    <label class="form-check-label" for="terms_and_conditions">
                                        {{ __('I accept') }} <a :href="`/termsAndConditions`" target="_blank">{{ __('Terms and conditions') }}.</a>
                                    </label>
                                </div>

                                <button 
                                    type="button"
                                    class="btn btn-block"
                                    style="background-color: #565656;color: #fff;" 
                                    v-on:click="saveLead"
                                    :disabled="disableButton">
                                    {{ __('Next') }} <i class="fas fa-spin fa-spinner" v-if="loader"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 pl-xl-5" v-if="hotel">
                        <div class="card border-0 shadow">
                            <div class="card-body p-4">
                                <div class="text-block pb-3">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <img v-bind:src="hotel.ImageProfile"
                                            alt="" class="rounded w-100 mb-2" style="height: 200px">

                                            <h4>{{hotel.ResortName}}</h4>
                                            <p class="text-muted text-sm mb-0">{{hotel.UnitType}} {{ __('in') }}
                                                {{hotel.ResortCity}}, {{hotel.ResortCountry}}</p>
                                            <img src="/assets/cheque_2.png"> {{hotel.InvTypeInDetail2}}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block py-3">
                                    <ul class="list-unstyled mb-3">
                                        <li><i class="fas fa-users fa-fw mr-2" style="color: #565656"></i>{{hotel.MaxOccupancy}}
                                            {{ __('People') }}.
                                        </li>
                                        <li>
                                            <i class="fas fa-moon mr-2" style="color: #565656"></i>
                                            {{hotel.Nights}} {{ __('Nights') }}</th>
                                        </li>
                                    </ul>
                                    <div class="text-center border py-2 text-weight-500">
                                        <p>
                                            Check In: <br>
                                            {{hotel.CheckInDate | formatDate}}
                                        </p>
                                        <p>
                                            Check Out: <br>
                                            {{hotel.CheckOutDate | formatDate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="text-block pt-3 pb-0">
                                    <table class="w-100">
                                        <tbody>
                                            <tr>
                                                <th class="font-weight-normal py-2">$
                                                    {{formatPrice(hotel.BasePublicPrice)}} <span style="font-size: 10px;">{{ currency }}</span> {{ __('for') }} {{hotel.Nights}}
                                                    {{ __('Nights') }}</th>
                                                <td class="text-right py-2">$ {{formatPrice(hotel.BasePublicPrice)}}
                                                    <span style="font-size: 10px;">{{ currency }}</span></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="border-top font-weight-bold" style="color: #565656;">
                                                <th class="pt-3">Total</th>
                                                <td class="font-weight-bold text-right pt-3">$
                                                    {{formatPrice(hotel.BasePublicPrice)}} <span style="font-size: 10px;">{{ currency }}</span></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div v-if="this.hotel.InvTypeInDetail2 == 'All Inclusive'" class="text-center" style="margin-bottom: 20px;margin-top: 30px;">
                                    <small style="text-align: end;color: #7A7A7A;">{{ __('Booking Fees') }}</small>
                                    <table class="table text-sm mb-0" style="background-color: #00aed214;">
                                        <tbody>
                                            <tr>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('From to') }}</th>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('Fees') }}</th>
                                                <th class="pl-0 border-0 text-weight-normal">{{ __('Unit Type') }}</th>
                                            </tr>
                                            <tr v-for="option in fee">
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">{{ option[0] }}</th>
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">Fee {{ option[3] }}: {{option[1]}} {{option[2]}} per {{option[4]}} {{option[5]}}</th>
                                                <th style="font-size: 12px;" class="pl-0 border-0 text-weight-normal">{{ option[6] }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        props: ['currency', 'lang', 'currencyValue','codes'],
        data() {
            return {
                hotel: null,
                name: '',
                last_name: '',
                email: '',
                fee:[],
                international_code: '',
                phone_number: '',
                terms_and_conditions: false,
                errors: [],
                citySearch: null,
                address: '',
                zipCode: null,
                country: null,
                state: null,
                city: null,
                countries: [], 
                loader: false
            }
        },
        mounted() {
            this.hotel = JSON.parse(localStorage.getItem('selectedHotel'))

            if(this.hotel.InvTypeInDetail2 == "All Inclusive"){
                var fee = this.hotel.AIFees.split('<Fee>');

                for(var i = 0; i < fee.length; i++){
                    var val = fee[i].replace('</Fee>', '');
                    var val = val.split('|');
                    if(val.length > 1){
                        this.fee.push(val);
                    }
                }
            }

            // Se recalcula el precio public en caso de algun cambio de divisa despues de selecionar el resort
            this.hotel.PublicPrice = Math.round((this.hotel.BasePublicPrice * parseFloat(this.currencyValue)) * 100) / 100;
            localStorage.setItem('selectedHotel', JSON.stringify(this.hotel));

            this.citySearch = localStorage.getItem('city');
            this.loadCountries()
        },
        computed: {
            countryOptions() {
                return [].concat(this.countries.map(country => {
                    return {
                        text: country.country_name.toUpperCase(),
                        value: country.id
                    }
                }));
            },
            countrySelected() {
                if (this.country === null) return null;

                return this.countries.find(c => c.id == this.country);
            },
            cityOptions() {
                let options = [{text: this.__('Choose a city'), value: null}];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.cities.map(city => {
                        return {
                            text: city.city_name.toUpperCase(),
                            value: city.id
                        }
                    }));
                }

                return options;
            },
            stateOptions() {
                let options = [];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.states.map(state => {
                        return {
                            text: state.name.toUpperCase(),
                            value: state.id
                        }
                    }));

                    if (options.length > 0) {
                        options = [{text: this.__('Choose a state'), value: null}].concat(options);
                    }
                }

                return options;
            },
            disableButton() {
                return (
                    !this.name || 
                    !this.last_name || 
                    !this.international_code || 
                    !this.phone_number || 
                    !this.email || 
                    !this.validEmail(this.email) ||
                    !this.city ||
                    !this.address ||
                    !this.country ||
                    !this.zipCode
                ) || !this.terms_and_conditions || this.loader;
            }
        },
        methods: {
            async loadCountries() {
                let { data: countries } = await axios.get('/api/countries');

                this.countries = countries;
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            updateValue(event) {
                const value = event.target.value
                if (String(value).length <= 10) {
                    //this.cell_phone = value
                }
                this.$forceUpdate()
            },
            validEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            saveLead: function () {
                this.errors = []

                if (!this.name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.last_name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.email) {
                    this.errors.push('El correo electrónico es obligatorio.')
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('El correo electrónico debe ser válido.')
                }
                
                if (this.errors.length > 0) {
                    return true
                }
                                
                this.loader = true;
                
                axios.post('/api/reservation', {
                        client: {
                            name: this.name,
                            last_name: this.last_name,
                            email: this.email,
                            international_code: this.international_code,
                            phone_number: this.phone_number,
                            address: this.address,
                            city_id: this.city,
                            state_id: this.state,
                            zip_code: this.zipCode,
                        },
                        hotel: this.hotel,
                        city: this.citySearch,
                        currency: this.currency
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            if (response.data.status == 'success') {
                                localStorage.setItem('client_id', response.data.client_id);
                                localStorage.setItem('reservation_id', response.data.reservation_id);
                                // localStorage.removeItem('selectedHotel');
                                window.location.href = '/checkout';
                                // window.location.href = '/details/payment';
                            }
                        }
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function () {
                        $('#cover-spin').hide()
                    });
            }
        }
    }

</script>
