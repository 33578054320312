<template>
    <div>
        <section class="py-5">
            <div class="container">
                <div class="modal" id="modalLaterDispo" style="display: none;">
                    <div class="modal-dialog-centered">
                        <div class="modal-container">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" v-on:click="closemodalLaterDispo()" aria-label="Close"><span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h1 style="text-align: justify;">{{ __('The Unit is no longer available, it is necessary to requote.') }}</h1>
                                <button v-on:click="closemodalLaterDispo()" style="btn btn-primary">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-7 border-left-2">
                        <div class="alert alert-info mb-3">
                            <svg class="bi bi-alert-triangle" width="1rem" height="1rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M9.938 4.016a.146.146 0 00-.054.057L3.027 15.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L10.12 4.073a.146.146 0 00-.054-.057.13.13 0 00-.063-.016.13.13 0 00-.064.016zm1.043-.45a1.13 1.13 0 00-1.96 0L2.166 15.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L10.982 3.566z" clip-rule="evenodd" />
                                <rect width="2" height="2" x="9.002" y="13" rx="1" />
                                <path d="M9.1 7.995a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0L9.1 7.995z" />
                            </svg>
                            {{ __('This accommodation is seen many times a day') }}.
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto mb-2 pl-4" style="color: #4a4a4a;">
                                <h3>{{ __('General data') }}</h3>
                            </div>
                            <div class="col-auto text-muted px-4">
                                <h3>{{ __('Step 1') }}</h3>
                            </div>
                        </div>
                        <!-- <h3 class="mb-2 text-primary col-12"> {{ __('General data') }} <span class="text-muted float-right">{{ __('Step 1') }}</span></h3> -->
                        <div class="bg-white p-3 shadow">
                            <form novalidate="novalidate">
                                <div class="mb-4">
                                    <h5>{{ __('Reservation holder') }}</h5>
                                </div>
                                <p v-if="errors.length">
                                    <!-- <b>Por favor, corrija el(los) siguiente(s) error(es):</b> -->
                                    <ul>
                                        <li v-for="error in errors">{{ error }}</li>
                                    </ul>
                                </p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Traveler Name') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input type="text" class="form-control form__input" name="name" required="true" :placeholder="__('Name')" aria-label="Name" v-model="name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Traveler Last Name') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input type="text" class="form-control form__input" name="name" required="true" :placeholder="__('Last Name')" aria-label="Last Name" v-model="lastname" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Address') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input type="text" class="form-control form__input" name="address" required="" :placeholder="__('Address')" aria-label="Address" v-model="address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Zip code') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input type="number" class="form-control form__input" name="zip_code" required="" placeholder="77711" aria-label="Código postal" v-model="zipCode" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Country') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select class="form-control form__input" name="country" required="true" aria-label="Country" v-model="country">
                                                    <option :value="null">{{ __('Choose a option') }}</option>
                                                    <option :value="121">Mexico</option>
                                                    <option :value="191">USA</option>
                                                    <option :value="32">Canada</option>
                                                    <option :value="40">Colombia</option>
                                                    <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="stateOptions.length > 0">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('State') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select class="form-control form__input" name="state" :placeholder="__('State')" aria-label="state" v-model="state">
                                                    <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('City') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input class="form-control form__input" name="city_id" required="" :placeholder="__('City')" aria-label="City" v-model="city" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group form-check text-right mt-3 mb-3">
                                    <input type="checkbox" class="form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
                                    <label class="form-check-label" for="terms_and_conditions">
                                        {{ __('I accept') }} <a :href="`/termsAndConditions`" target="_blank">{{ __('Terms and conditions') }}.</a>
                                    </label>
                                </div>
                                <button type="button" style="background-color: #4a4a4a; color: #fff;" class="btn btn-block" v-on:click="saveLead" :disabled="disableButton">
                                    {{ __('Next') }} <i class="fas fa-spin fa-spinner" v-if="loader"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 pl-xl-5" v-if="hotel">
                        <div class="card border-0 shadow">
                            <div class="card-body p-4">
                                <div class="text-block pb-3">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <img v-bind:src="'https://www.rci.com/static/Resorts/Assets/'+hotel.Resort_ID+'E01.jpg'" alt="" class="rounded w-100 mb-2" style="height: 200px">
                                            <h4>{{hotel.Resort_name}}</h4>
                                            <p class="text-muted text-sm mb-0">{{hotel.Description_bedroom}} {{ __('in') }}
                                                {{hotel.City}}, {{hotel.Country}}</p>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" v-model="idUse" value="{idUser}" />
                                <div class="text-block py-3">
                                    <ul class="list-unstyled mb-3">
                                        <li><i class="fas fa-users fa-fw mr-2" style="color: #4a4a4a;"></i>{{hotel.MaxOccupancy}}
                                            {{ __('People') }}.
                                        </li>
                                        <li>
                                            <i class="fas fa-moon mr-2" style="color: #4a4a4a;"></i>
                                            {{hotel.noches}} {{ __('Nights') }}</th>
                                        </li>
                                    </ul>
                                    <div class="text-center border py-2 text-weight-500">
                                        <p>
                                            Check In: <br>
                                            {{ fnformatDate(hotel.CheckInDate) }}
                                        </p>
                                        <p>
                                            Check Out: <br>
                                            {{fnformatDate(hotel.CheckOutDate) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="text-block pt-3 pb-0">
                                    <table class="w-100">
                                        <tbody>
                                            <tr>
                                                <th class="font-weight-normal py-2">$
                                                    {{formatPrice(hotel.priceDlls * currencyValue)}} {{ currency }} {{ __('for') }} {{hotel.noches}}
                                                    {{ __('Nights') }}</th>
                                                <td class="text-right py-2">$ {{formatPrice(hotel.priceDlls * currencyValue)}}
                                                    {{ currency }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="border-top font-weight-bold" style="color: #4a4a4a;">
                                                <th class="pt-3">Total</th>
                                                <td class="font-weight-bold text-right pt-3">$
                                                    {{formatPrice(hotel.priceDlls * currencyValue)}} {{ currency }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="card-footer bg-primary-light py-4 border-0"> -->
                            <!-- <div class="media align-items-center">
                                    <div class="media-body">
                                        <h6 class="text-primary"><i class="fas fa-gift"></i> Reservación garantizada.</h6>
                                        <p class="text-sm text-primary opacity-8 mb-0">
                                            Tu reservación esta segura. Cancela con anticipación y obtén un reembolso.
                                            Consulta nuestros términos y condiciones.
                                        </p>
                                    </div>
                                </div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['currency', 'lang', 'currencyValue', 'codes', 'userid', 'user'],
    data() {
        return {
            idUse: null,
            hotel: null,
            name: '',
            lastname: '',
            international_code: '',
            phone_number: '',
            terms_and_conditions: true,
            errors: [],
            citySearch: null,
            address: '',
            zipCode: null,
            country: null,
            state: null,
            city: null,
            countries: [],
            loader: false,
            startMonth: '',
            endMonth: '',
            countrySearch: '',
            stateSearch: '',
            url: ''
        }
    },
    mounted() {
        this.hotel = JSON.parse(localStorage.getItem('selectedHotel'));
        this.startMonth = localStorage.getItem('startMonth');
        this.endMonth = localStorage.getItem('endMonth');
        this.countrySearch = localStorage.getItem('country');

        if (localStorage.getItem('state')) {
            this.stateSearch = localStorage.getItem('state');
        }
        // Se recalcula el precio public en caso de algun cambio de divisa despues de selecionar el resort
        // this.hotel.PublicPrice = Math.round((this.hotel.BasePublicPrice * parseFloat(this.currencyValue)) * 100) / 100;
        localStorage.setItem('selectedHotel', JSON.stringify(this.hotel));

        this.citySearch = localStorage.getItem('city');
        this.loadCountries()
    },
    computed: {
        countryOptions() {
            return [].concat(this.countries.map(country => {
                return {
                    text: country.NOMBRE_PAIS,
                    value: country.CVE_PAIS
                }
            }));
        },
        countrySelected() {
            if (this.country === null) return null;

            return this.countries.find(c => c.CVE_PAIS == this.country);
        },
        stateOptions() {
            let options = [];
            if (this.countrySelected !== null) {
                options = options.concat(this.countrySelected.states.map(state => {
                    return {
                        text: state.NOMBRE_ESTADO,
                        value: state.CVE_ESTADO
                    }
                }));

                if (options.length > 0) {
                    options = [{ text: this.__('Choose a state'), value: null }].concat(options);
                }
            }

            return options;
        },
        disableButton() {
            return (
                !this.city ||
                !this.address ||
                !this.country ||
                !this.zipCode
            ) || !this.terms_and_conditions || this.loader;
        }
    },
    methods: {
        closemodalLaterDispo() {
            window.location.href = this.url;
        },
        fnformatDate(value) {
            var months = ['', "Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var [year, month, day] = value.split('-');
            day = day.split(' ');
            var dd = this.addLeadingZeros(day[0], 2) + " / " + months[parseInt(month)] + " / " + this.addLeadingZeros(year, 4);
            return dd;
        },
        addLeadingZeros(number, targetLength) {
            var sign = number < 0 ? '-' : ''
            var output = Math.abs(number).toString()
            while (output.length < targetLength) {
                output = '0' + output
            }
            return sign + output
        },
        async loadCountries() {
            let { data: countries } = await axios.get('/api/countries');
            this.countries = countries;
        },
        changeCheck() {
            if (this.user.related_person_name.length > 1) {
                if ($('#checkRelated').is(':checked')) {
                    const $miCheckboxRelated = document.querySelector("#checkRelated");
                    $miCheckboxRelated.checked = true;

                    const $miCheckbox = document.querySelector("#checkHolder");
                    $miCheckbox.checked = false;
                } else {
                    const $miCheckboxRelated = document.querySelector("#checkRelated");
                    $miCheckboxRelated.checked = false;

                    const $miCheckbox = document.querySelector("#checkHolder");
                    $miCheckbox.checked = true;
                }
            } else {
                const $miCheckbox = document.querySelector("#checkHolder");
                $miCheckbox.checked = true;
            }
        },
        changeCheckHolder() {
            if (this.user.related_person_name.length > 1) {
                if ($('#checkHolder').is(':checked')) {
                    const $miCheckbox = document.querySelector("#checkHolder");
                    $miCheckbox.checked = true;

                    const $miCheckboxRelated = document.querySelector("#checkRelated");
                    $miCheckboxRelated.checked = false;
                } else {
                    const $miCheckbox = document.querySelector("#checkHolder");
                    $miCheckbox.checked = false;

                    const $miCheckboxRelated = document.querySelector("#checkRelated");
                    $miCheckboxRelated.checked = true;
                }
            } else {
                const $miCheckbox = document.querySelector("#checkHolder");
                $miCheckbox.checked = true;
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        updateValue(event) {
            const value = event.target.value
            if (String(value).length <= 10) {
                //this.cell_phone = value
            }
            this.$forceUpdate()
        },
        validEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        saveLead: function() {
            this.errors = []
            let th = this;
            if (this.errors.length > 0) {
                return true
            }
            localStorage.setItem('selectedHotel', JSON.stringify(this.hotel));
            this.loader = true;
            axios.post('/api/reservationAdd', {
                    client: {
                        address: this.address,
                        city_id: this.city,
                        state_id: this.state,
                        zip_code: this.zipCode,
                        country: this.country,
                        name: this.name,
                        lastname: this.lastname
                    },
                    hotel: this.hotel,
                    citySearch: this.citySearch,
                    countrySearch: this.countrySearch,
                    stateSearch: this.countrySearch,
                    endMonth: this.endMonth,
                    startMonth: this.startMonth,
                    currency: this.currency,
                    idUser: this.userid
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.status == 'success') {
                            localStorage.setItem('client_id', response.data.client_id);
                            localStorage.setItem('TravelerCity', response.data.TravelerCity);
                            localStorage.setItem('TravelerAddress', response.data.TravelerAddress);
                            localStorage.setItem('TravelerState', response.data.TravelerState);
                            localStorage.setItem('TravelerZipCode', response.data.TravelerZipCode);
                            localStorage.setItem('TravelerCountry', response.data.TravelerCountry);
                            localStorage.setItem('TravelerName', response.data.TravelerName);
                            localStorage.setItem('TravelerLastName', response.data.TravelerLastName);
                            localStorage.setItem('IDINVRESERVACIONES', response.data.IDINVRESERVACIONES);
                            localStorage.setItem('IDRESERVATIONINTERNA', response.data.IDRESERVATIONINTERNA);
                            window.location.href = '/checkout';
                        } else {
                            th.url = '/list/hotels?country=' + response.data.countrySearch + '&city=' + response.data.citySearch + '&startMonth=' + response.data.startMonth + '&endMonth=' + response.data.endMonth + '&country=' + response.data.countrySearch + '&city=' + response.data.citySearch + '&startMonth=' + response.data.startMonth + '&endMonth=' + response.data.endMonth;
                            document.getElementById("modalLaterDispo").style.display = "table";
                            //  window.location.href = url;
                        }
                    }
                })
                .catch(function(error) {

                })
                .finally(function() {
                    $('#cover-spin').hide()
                });
        }
    }
}

</script>
