<template>
    <div>
        <div>
            <div class="row">
                <div class="col-md-12">
                    <h5 style="color:#565656;" v-if="hotels">
                        {{ __(':hotels offers found' , {hotels: this.counter}) }}, {{ __( city) }}, {{country}}
                        <span style="float: right;">{{this.startMonth }} - {{ this.endMonth}}</span>
                    </h5>
                </div>
                <div class="col-md-3 lateralFilter">
                    <div class="rounded p-3 card" style="">
                        <form action="/list/hotels" method="GET" ref="hotelAvailabilityForm" @submit="submitForm()">
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select v-model="countryLocal" @change="getStates()" class="form-control border-left-0 pl-0" name="country">
                                                <option v-for="option in countries" :value="option.Country">{{ option.Country }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" v-show="states.length">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select v-model="stateSelected_" @change="getCities()" class="form-control border-left-0 pl-0" name="state">
                                                <option v-for="option in states" :value="option.Nombre">{{ option.Nombre }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select v-model="city_" @change="getMonths()" class="form-control border-left-0 pl-0" name="city">
                                                <option v-for="option in cities_" :value="option.City">{{ __( option.City) }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1" id="basic-addon1">
                                                    <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                                        <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select v-model="startDateMonth" name="startMonth" @change="selectedMonth()" class="form-control border-left-0 pl-0">
                                                <option v-for="option in monthsavail" :key="option.value" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                                            </select>
                                        </div>
                                        <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-white px-1" id="basic-addon2">
                                                <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                                <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                                    <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                        <select v-model="endDateMonth" name="endMonth" class="form-control border-left-0 pl-0">
                                            <option v-for="option in endMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                                        </select>
                                    </div>
                                    <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <center>
                                        <button type="submit" style="background-color: #474C52
 !important;color: #fff; border-color: #474C52
 !important; margin-top: 15px;width: 100%" class="btn" :disabled="loader">
                                            {{ __('Search') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i>
                                        </button>
                                    </center>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                            </div>
                        </form>
                    </div><br>
                    <div class="card mb-3">
                        <center>
                            <!--button class="btn mb-3" style="background-color: #474C52
; color: #fff; width: 80%;margin-top: 10px;">{{ __('Filters') }} </button-->
                        </center>
                        <div style="margin-left: 7px;">{{ __('Resort name') }}</div>
                        <center>
                            <select style="width: 95%;" class="form-control" v-model="resortName">
                                <option v-for="rn in hotelesname" :key="rn" :value="rn">{{ rn }}</option>
                            </select>
                        </center><br>
                        <div style="margin-left: 7px;" v-if="city_ == 'All cities'">{{ __('City') }}</div>
                         <center v-if="city_ == 'All cities'">
                            <select style="width: 95%;" v-model="citySelected" class="form-control" v-if="city_ == 'All cities'">
                                <option :value="'All cities'">{{ __('All cities') }}</option>
                                <option v-for="cit in citiesarray" :value="cit">{{cit}}</option>
                            </select><br>
                        </center>
                        <div style="margin-left: 7px;">{{ __('Sleeps') }}</div>
                          <center>
                            <div v-for="oc in maxocupancy" style="text-align: start;margin-left: 7px; color: #434343 !important;">
                                <input type="checkbox" :id="oc" @change="selectedOccupancy(oc)"> <i v-for="index in parseInt(oc)" class="fa fa-user" style="color:#434343; width: 17px;height: 19px;" aria-hidden="true"></i>
                            </div><br>
                        </center>
                        <div style="margin-left: 7px;">{{ __('Weeks') }}</div>
                        <center>
                            <div v-for="we in weeks" style="text-align: start;margin-left: 7px; color: #434343 !important;">
                                <input type="checkbox" @change="selectedWeeks(we)" :id="we"> {{ __( we ) }}
                            </div><br>
                            <br>
                            <hr style="width: 95%;">
                            <div class="row">
                                <div class="col" style="text-align: start; color:#434343 !important;">
                                    <b style="font-size: 16px;">{{ __('Price') }}</b>
                                </div>
                                <div class="col" style="text-align: start; color:#434343 !important; font-size: 14px;">
                                    ${{ formatPrice(this.priceResort) }} {{ currency }}
                                </div>
                            </div>
                            <input type="range" style="width: 95%;" placeholder="priceResort" class="form-control" :min="this.min" :max="this.max" v-model="priceResort">
                        </center>
                    </div>
                </div>
                 <div class="col-md-9 lateralFilter" v-if="loader">
                    <loader-component></loader-component>
                </div>
                <div class="col-md-9 lateralFilter" v-if="hotels">
                    <div class="mb-3 row" v-for="(hotel, index) in paginated('hotels')" v-bind:key="`${index}`">
                        <div class="col-md-4">
                            <a style="cursor: pointer;" v-on:click="viewModalLater(hotel.ResortId)">
                                <img v-bind:src="'https://www.rci.com/static/Resorts/Assets/'+hotel.ResortId+'E01.jpg'" style="height: 200px;margin-bottom: 15px;margin-top: 15px;" class="card-img" alt="...">
                            </a>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-grow-1" style="margin-left: -50px; height: 125px;">
                                                <h5>
                                                    <a style="cursor: pointer;" v-on:click="viewModalLater(hotel.ResortId)">{{hotel.ResortName}}</a>
                                                </h5>
                                                <p>
                                                    <span>
                                                        <a style="cursor: pointer;" v-on:click="viewModalLaterMap(hotel.ResortId)">
                                                            <span><b>{{ hotel.ResortCity }}</b></span><br>
                                                            {{ hotel.ResortAddress1 }} {{ hotel.ResortAddress2 }} {{ hotel.ResortAddress3 }}
                                                        </a>
                                                    </span><br>
                                                    <div v-for="(unit,i) in hotel.unidades" :key="i">
                                                        {{unit}} 
                                                    </div>
                                                </p>
                                                <div v-for="(det,i) in hotel['unitsTypes']">
                                                    <img style="width: 12px;height: 12px;" src="/images/hotel.png" alt=""><span style="color: #7A7A7A;font-size: 14px;">
                                                        {{det.unit}} / <b>{{det.person}} {{ __('Guest') }}</b>
                                                    </span>
                                                </div>
                                                <img style="width: 140px;margin-top: 25px;" src="/images/garantice.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" style="background-color: #F1F2F4;border-radius: 15px;">
                                    <div class="d-flex flex-column" style="margin-bottom: 0px !important;background-color: #F1F2F4;">
                                        <span style="border-radius:15px; font-size: 30px;text-align: end;margin-top: 12px;" class="mb-0">
                                            <b>
                                                ${{ formatPrice(hotel.price) }} <span style="font-size: 14px;">{{ currency }}</span>
                                            </b>
                                        </span>
                                        <small style="text-align: end;" class="text-muted">{{ __('Price for total nights') }}</small>
                                        <div v-if="lang == 'en'" style="text-align: end;margin-top: 30px;margin-bottom: 20px;">
                                            <div class="position-relative relevant-label">
                                                <div>${{ hotel['AvgPerNight'] }}
                                                </div>
                                                <div style="font-size: 14px;">{{ __('Avg/Night') }}</div>
                                            </div>
                                        </div>
                                        <div v-if="lang == 'es'" style="text-align: end;margin-top: 30px;margin-bottom: 20px;">
                                            <div class="position-relative relevant-label">
                                                <div>${{ hotel['AvgPerNight'] }}
                                                </div>
                                                <div style="font-size: 14px">{{ __('Avg/Night') }}</div>
                                            </div>
                                        </div>
                                        <a v-on:click="seeMore(hotel.ResortId, hotel.InvType,hotel.price)" :id="'more'+hotel.InvType+hotel.ResortId+hotel.price" class="customColor21 btn btn-block btn-outline-default border-width-2 no-hover" style="background-color: #474C52 !important;padding: 6px; color: #fff;">
                                            {{ __('Show me more') }}
                                        </a>
                                        <a v-on:click="seeLess(hotel.ResortId, hotel.InvType,hotel.price)" :id="'less'+hotel.InvType+hotel.ResortId+hotel.price" class="customColor210 btn btn-block btn-outline-default border-width-2 no-hover" style="background-color: #474C52 !important;padding: 6px; color: #fff; display: none;">
                                            {{ __('Show me less') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="hotel.details.length >= 8? 'col-md-12 more8': 'col-md-12 minus8'" :id="'div'+hotel.InvType+hotel.ResortId+hotel.price">
                            <table :class="hotel.details.length >= 8?'tableminus':'tablemore'">
                                <tr style="background-color: #ECECEC;height: 40px;border-top-color:#C4C4C4;border-bottom-color: #045882;">
                                    <th style="text-align: center;font-size: 16px;">{{ __('Nights') }}</th>
                                    <th style="text-align: center;font-size: 16px;">{{ __('Guest') }}</th>
                                    <th style="text-align: center;font-size: 16px;">{{ __('Unit Type') }}</th>
                                    <th style="text-align: center;font-size: 16px;">{{ __('Check In') }}</th>
                                    <th style="text-align: center;font-size: 16px;">{{ __('Check Out') }}</th>
                                    <th style="text-align: center;"></th>
                                </tr>
                                <tr v-for="(det,i) in hotel.details" :key="i">
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        {{det.noches}}
                                    </td>
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        <span v-for="index in parseInt(det.MaxOccupancy)">
                                            <i class="fa fa-user" style="color:#565656; width: 17px;height: 19px;" aria-hidden="true"></i>
                                            <br v-if="det.MaxOccupancy > 2 && index == (parseInt(parseInt(det.MaxOccupancy)/2))">
                                        </span>
                                    </td>
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        {{det.Description_bedroom}}
                                    </td>
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        <i style="color: #ADADAD" class="fa fa-calendar" aria-hidden="true"></i> {{fnformatDate(det.CheckInDate)}}
                                    </td>
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        <i style="color: #ADADAD" class="fa fa-calendar" aria-hidden="true"></i> {{fnformatDate(det.CheckOutDate)}}
                                    </td>
                                    <td :class="(i%2)!=0? 'ccolor':'descolor'">
                                        <a v-on:click="selectHotel(det, hotel['details'])" class="btn btn-block no-hover" style="background-color: #474c52; color: #fff;width: 100%">
                                            {{ __('Select') }}
                                            <i v-if="loader" class="fas fa-spin fa-spinner"></i></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-2">
                                <div class="modal" :id="'modalLater'+hotel.ResortId" style="display: none;">
                                    <div class="modal-dialog-centered">
                                        <div class="modal-container">
                                            <div class="modal-header">
                                                <h5>
                                                    {{hotel.ResortName}}
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal" v-on:click="closeModalLater(hotel.ResortId)" aria-label="Close"><span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div v-if="images.length" :id="'carouselExampleControls'+hotel.ResortId" class="carousel slide" data-ride="carousel">
                                                            <div class="carousel-inner" style="max-width: 400px;">
                                                                <div class="carousel-item active" style="max-height: 300px;">
                                                                    <img v-if="images" class="d-block w-100" v-bind:src="images[0].imagen" alt="First slide">
                                                                </div>
                                                                <div v-if="images && index != 0" v-for="(asset, index) of images" class="carousel-item" style="max-height: 300px;">
                                                                    <img class="d-block w-100" v-bind:src="asset.imagen">
                                                                </div>
                                                            </div>
                                                            <a class="carousel-control-prev" :id="'btnprev'+hotel.ResortId" :href="'#carouselExampleControls'+hotel.ResortId" role="button" data-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span class="sr-only">Previous</span>
                                                            </a>
                                                            <a class="carousel-control-next" :id="'btnnex'+hotel.ResortId" :href="'#carouselExampleControls'+hotel.ResortId" role="button" data-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span class="sr-only">Next</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="text-block text-break">
                                                            <h3 class="mb-3" style="color: #016799;">{{ __('About this hotel') }}</h3>
                                                            <p :id="'ResortHighLightsComplete'+hotel.ResortId" v-if="lang == 'es'">{{hotel.concatenadoEs}}</p>
                                                            <p :id="'ResortHighLightsComplete'+hotel.ResortId" v-if="lang == 'en'">{{hotel.concatenadoEn}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal" :id="'modalLaterMap'+hotel.ResortId" style="display: none;">
                                    <div class="modal-dialog-centered">
                                        <div class="modal-container">
                                            <div class="modal-header">
                                                <h5>
                                                    {{hotel.ResortName}}
                                                </h5>
                                                <button type="button" class="close" data-dismiss="modal" v-on:click="closeModalLaterMap(hotel.ResortId)" aria-label="Close"><span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <h3 class="mb-3" style="color: #016799;">{{ __('Ubication') }}</h3>
                                                        <span><b>{{ hotel.ResortCity }}</b></span><br>
                                                        <p>{{ hotel.ResortAddress1 }} {{ hotel.ResortAddress2 }} {{ hotel.ResortAddress3 }}</p>
                                                        <googlemap
                                                        :lat="parseFloat(hotel.Latitude)"
                                                        :long="parseFloat(hotel.Longitude)"
                                                        ></googlemap>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 listComplete" style="margin-bottom: 10px;">
                    <center>
                        <button type="btn" v-on:click="seeFiltros()" style="background-color: #474C52
                        !important;color: #fff; border-color: #474C52
                        !important; margin-top: 15px;" class="btn"><i class="fa fa-chevron-circle-down" aria-hidden="true"></i> {{ __('Filtros') }}
                        </button>
                        <button type="btn" v-on:click="seeBuscador()" style="background-color: #474C52
                        !important;color: #fff; border-color: #474C52
                        !important; margin-top: 15px;" class="btn"><i class="fa fa-chevron-circle-down" aria-hidden="true"></i> {{ __('Buscador') }}
                        </button>
                    </center>
                </div>
                <div class="col-md-12 listComplete" v-if="hoteles">
                    <div class="card mb-3" v-show="filtros">
                        <center>
                            <!--button class="btn mb-3" style="background-color: #474C52
; color: #fff; width: 80%;">Filtros</button-->
                        </center>
                        <div style="margin-left: 7px;">Resort name</div>
                        <center>
                            <select style="width: 95%;" class="form-control" v-model="resortName">
                                <option v-for="rn in hotelesname" :key="rn" :value="rn">{{ rn }}</option>
                            </select>
                        </center><br>
                        <div v-if="city_ == 'All cities'">{{ __('City') }}</div>
                        <center>
                            <select style="width: 95%;" v-model="citySelected" class="form-control" v-if="city_ == 'All cities'">
                                <option :value="'All cities'">{{ __('All cities') }}</option>
                                <option v-for="cit in citiesarray" :value="cit">{{cit}}</option>
                            </select><br>
                        </center>
                        <div style="margin-left: 7px;">{{ __('Sleeps') }}</div>
                        <center>
                            <div v-for="oc in maxocupancy" style="text-align: start;margin-left: 7px; color: #434343 !important;">
                                <input type="checkbox" :id="oc" @change="selectedOccupancy(oc)"> <i v-for="index in parseInt(oc)" class="fa fa-user" style="color:#434343; width: 17px;height: 19px;" aria-hidden="true"></i>
                            </div><br>
                        </center>
                        <div style="margin-left: 7px;">Weeks</div>
                        <center>
                            <div v-for="we in weeks" style="text-align: start;margin-left: 7px; color: #434343 !important;">
                                <input type="checkbox" @change="selectedWeeks(we)" :id="we"> {{ __( we ) }}
                            </div><br>
                            <br>
                            <hr style="width: 95%;">
                            <div class="row">
                                <div class="col" style="text-align: start; color:#434343 !important;">
                                    <b style="font-size: 16px;">Price</b>
                                </div>
                                <div class="col" style="text-align: start; color:#434343 !important; font-size: 14px;">
                                    ${{ formatPrice(this.priceResort) }} USD
                                </div>
                            </div>
                            <input type="range" style="width: 95%;" placeholder="priceResort" class="form-control" :min="this.min" :max="this.max" v-model="priceResort">
                        </center>
                    </div>
                    <div class="rounded p-3 card" v-show="buscador">
                        <form action="/list/hotels" method="GET" @submit="submitForm()" ref="hotelAvailabilityForm">
                            <div class="row">
                                <div class="col" v-show="!states">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select v-model="countryLocal" @change="getStates()" class="form-control border-left-0 pl-0" name="country">
                                                <option v-for="option in countries" :value="option.Country">{{ option.Country }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" v-show="states.length">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                             <select v-model="stateSelected_" @change="getCities()" class="form-control border-left-0 pl-0" name="state">
                                                <option v-for="option in states" :value="option.Nombre">{{ option.Nombre }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1">
                                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <select required v-model="city_" @change="getMonths()" class="form-control border-left-0 pl-0" name="city">
                                                <option v-for="option in cities_" :value="option.City">{{ __( option.City) }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-white px-1" id="basic-addon1">
                                                    <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                                    <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                                        <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                             <select required v-model="startDateMonth" @change="selectedMonth()" name="startMonth" class="form-control border-left-0 pl-0">
                                                <option v-for="option in monthsavail" :key="option.value" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                                            </select>
                                        </div>
                                        <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-white px-1" id="basic-addon2">
                                                <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                                <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                                    <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                        <select required v-model="endDateMonth" name="endMonth" class="form-control border-left-0 pl-0">
                                            <option v-for="option in endMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                                        </select>
                                    </div>
                                    <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <center>
                                        <button type="submit" style="background-color: #474C52
                                        !important;color: #fff; border-color: #474C52
                                        !important; margin-top: 15px;" class="btn" :disabled="loader">
                                        {{ __('Search') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i>
                                        </button>
                                    </center>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-12 listComplete" v-if="hotels">
                    <div class="card mb-3" v-for="(hotel, index) in paginated('hotels')" v-bind:key="`${index}`">
                        <div class="row">
                            <div class="col-md-12">
                                <a style="cursor: pointer;" v-on:click="viewModalLater(hotel.ResortId)">
                                    <img v-bind:src="'https://www.rci.com/static/Resorts/Assets/'+hotel.ResortId+'E01.jpg'" style="height: 200px;" class="card-img" alt="...">
                                </a>
                                <div class="col" style="width: 200px;box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);margin-left: 20px;position: absolute;margin-top: -15px; background-color: #fff;border-radius: 5px;text-align: center;">
                                    <b style="font-size: 18px;"><img src="/assets/cheque_2.png"></b>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card-body" style="padding: 0px !important;margin-top: 40px;">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <h5>
                                                        <a style="cursor: pointer;" v-on:click="viewModalLater(hotel.ResortId)">{{hotel.ResortName}}</a>
                                                    </h5>
                                                    <p>
                                                        <span>
                                                            <a style="cursor: pointer;" v-on:click="viewModalLaterMap(hotel.ResortId)">
                                                                <span><b>{{ hotel.ResortCity }}</b></span><br>
                                                            {{ hotel.ResortAddress1}} {{ hotel.ResortAddress2}} {{ hotel.ResortAddress3}}
                                                        </a>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-size: 22px;" class="mb-0">
                                                        <b>
                                                            ${{ formatPrice(hotel.price) }} <span style="font-size: 14px;">{{ currency }}</span>
                                                        </b>
                                                    </span>
                                                    <small style="color: #7A7A7A;">{{ __('Price for total nights') }}
                                                    </small>
                                                    <div class="position-relative relevant-label">
                                                        <div>${{ hotel.AvgPerNight }}
                                                        </div>
                                                        <div style="font-size: 14px">{{ __('Avg/Night') }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8"></div>
                                    <div class="col-md-4">
                                        <button v-on:click="seeMoreR(hotel.ResortId, hotel.InvType,hotel.price)" :id="'moreR'+hotel.InvType+hotel.ResortId+hotel.price" class="customColor21 btn btn-block btn-outline-default border-width-2 no-hover" style="margin-bottom: 15px;padding: 6px; color: #fff;">
                                            {{ __('Show me more') }}
                                        </button>
                                        <button v-on:click="seeLessR(hotel.ResortId, hotel.InvType,hotel.price)" :id="'lessR'+hotel.InvType+hotel.ResortId+hotel.price" class="customColor210 btn btn-block btn-outline-default border-width-2 no-hover" style="margin-bottom: 15px;padding: 6px; color: #fff; display: none;">
                                            {{ __('Show me less') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" style="display: none;" :id="'divR'+hotel.InvType+hotel.ResortId+hotel.price">
                            <div class="row" v-for="(det,i) in hotel.details" :key="i" style="font-size: 13px;background-color: #F6F6F6;width: 100%;margin-bottom: 10px;border-radius: 8px;">
                                <div class="col-12 col-md-12" style="color:#565656">
                                    <b style="display: inline;">{{ __('Nights') }}:</b> {{det.Nights}} /
                                    <b style="display: inline;">{{ __('Guest') }}:</b> {{det.MaxOccupancy}} /
                                    <b style="display: inline;">{{ __('Unit Type') }}:</b> {{det.UnitType}}
                                </div>
                                <div class="col-6 col-md-6">
                                    <div>
                                        <i style="color: #ADADAD" class="fa fa-calendar" aria-hidden="true"></i> {{fnformatDate(det.CheckInDate)}}
                                    </div>
                                    <div>
                                        <i style="color: #ADADAD" class="fa fa-calendar" aria-hidden="true"></i> {{fnformatDate(det.CheckOutDate)}}
                                    </div>
                                </div>
                                <div class="col-6 col-md-6" style="text-align: left;color:#565656">
                                    <a v-on:click="selectHotel(det, hotel['details'])" class="btn btn-block no-hover" style="background-color: #474c52; color: #fff;width: 100%;margin-bottom: 10px;">
                                        {{ __('Select') }}
                                        <i v-if="loader" class="fas fa-spin fa-spinner"></i>
                                    </a>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="col-md-6 text-center">
            <paginate v-if="hotelListFiltered.length" name="hotels" :list="hotelListFiltered" :per="15" class="pl-0">
            </paginate>
            <paginate-links v-if="hotelListFiltered.length" for="hotels" :classes="{
                            'ul': 'pagination',
                            '.next > a': 'page-link',
                            '.prev > a': 'page-link'
                        }" :show-step-links="true" :limit="15"></paginate-links>
            <div id="scrollContainer"></div>
        </div>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import parse from 'date-fns/parse';
import getMonth from 'date-fns/getMonth';

export default {
    props: ['city', 'startDate', 'endDate', 'userData', 'regularPrice', 'priceWithMembership', 'currency', 'country', 'currentSubscription', 'discountmember', 'membershipdiscountpercent', 'typediscountmembership', 'estate', 'countries', 'selected-start-date', 'selected-end-date', 'monthsvailability', 'bg', 'lang', 'hoteles', 'counter', 'cities', 'estates', 'hotelesname', 'maxocupancy', 'weeks', 'max', 'min','citiesarray'],
    data() {
        return {
            hotels: [],
            coupon: [],
            isLoaded: false,
            authUser: null,
            loader: false,
            banners: {},
            paginate: ['hotels'],
            maxOcuppancySelected: null,
            weeksSelected: null,
            occupancySelected: null,
            resortName: null,
            maxOcuppancies: [],
            resortNames: [],
            showModal: false,
            filtros: false,
            buscador: false,
            idUser: '',
            maxOcupancy: [],
            priceResort: 0,
            countryLocal: null,
            stateSelected_: null,
            citySelected:null,
            city_: null,
            startMonth: null,
            endMonth: null,
            startDateMonth: null,
            endDateMonth: null,
            errors: [],
            points: [],
            loading: false,
            loadingMonths: false,
            monthsLoaded: false,
            months: [],
            images: [],
            endMonthOptions: [],
            usaID: 195,
            dirty: false,
            priceDlls: 0,
            states: [],
            monthsavail: [],
            cities_:[]
        }
    },
    mounted() {;
        this.cities_ = this.cities;
        this.startDateMonth = this.startDate;
        this.endDateMonth = this.endDate;
        this.hotels = Object.values(this.hoteles);
        this.hotels.sort((a, b) => a.price - b.price);
        this.countryLocal = this.country
        this.city_ = this.city;
        this.stateSelected_ = this.estate;
        this.states = this.estates;
        this.monthsavail = this.monthsvailability;
        this.priceResort = this.max;

        var mes = this.startDateMonth.split(" ");
        for (var i = 0; i < this.monthsvailability.length; i++) {
            if (mes[0] <= this.monthsvailability[i].MesIni && mes[1] <= this.monthsvailability[i].YearIni) {
                this.endMonthOptions.push(this.monthsvailability[i]);
            }else if( mes[1] < this.monthsvailability[i].YearIni){
                    this.endMonthOptions.push(this.monthsvailability[i]);
            }
        }
    },
    methods: {
        submitForm() {
            this.loader = true;
            this.hotels = [];
        },
        async selectedMonth() {
            this.endMonth = "";
            this.endMonthOptions = [];
            var addMonthF = false;
            var mes = this.startDateMonth.split(" ");
            for (var i = 0; i < this.monthsavail.length; i++) {
                if (mes[0] <= this.monthsavail[i].MesIni && mes[1] <= this.monthsavail[i].YearIni) {
                    this.endMonthOptions.push(this.monthsavail[i]);
                }else if( mes[1] < this.monthsavail[i].YearIni){
                    this.endMonthOptions.push(this.monthsavail[i]);
                }
            }
        },
        async getCities() {
            this.months = [];
            this.monthsF = [];
            this.city_ = null;
            this.loading = true;
            this.startMonth = '';
            this.endMonth = '';
            let country = this.countryLocal;
            let state = this.stateSelected_;
            let response = await axios.get('/api/getCities', { params: { state, country } });
            this.cities_ = response.data;
            this.loading = false;
        },
        async getStates() {
            this.states = [];
            this.cities_ = [];
            this.months = [];
            this.monthsF = [];
            this.startDateMonth = "";
            this.monthsavail = "";
            this.endDateMonth = [];
            this.endMonthOptions = [];
            this.stateSelected = null;
            this.citySelected = null;
            this.startMonth = '';
            this.endMonth = '';
            if (this.countryLocal == 'USA'/* || this.countryLocal == 'Canada'*/) {
                this.loading = true;
                let country = this.countryLocal;
                let response = await axios.get('/api/getStates', { params: { country } });
                this.states = response.data;
                this.loading = false;
            } else {
                this.getCitiesCountry();
            }
        },
        async getCitiesCountry() {
            this.loading = true;
            let country = this.countryLocal;
            let response = await axios.get('/api/getCitiesCountry', { params: { country } });
            this.cities_ = response.data;
            this.loading = false;
        },
        ResortHighLightsComplete($id) {
            document.getElementById("ResortHighLightsComplete" + $id).style.display = "block";
            document.getElementById("ResortHighLightsCut" + $id).style.display = "none";
            document.getElementById("ResortHighLightsCompletebtn" + $id).style.display = "none";
            document.getElementById("ResortHighLightsCutbtn" + $id).style.display = "block";

        },
        seeMoreResortHighLightsLess($id) {
            document.getElementById("ResortHighLightsComplete" + $id).style.display = "none";
            document.getElementById("ResortHighLightsCut" + $id).style.display = "block";
            document.getElementById("ResortHighLightsCompletebtn" + $id).style.display = "block";
            document.getElementById("ResortHighLightsCutbtn" + $id).style.display = "none";

        },
        selectHotel: function(hotel, comp) {
            $('#cover-spin').show(0)
            this.loader = true;
            hotel.isLoggedIn = false;

            if (this.authUser) {
                hotel.isLoggedIn = true
            }
            localStorage.setItem('selectedHotel', JSON.stringify(hotel));
            localStorage.setItem('details', JSON.stringify(comp));
            localStorage.setItem('coupon', JSON.stringify(this.coupon));
            localStorage.setItem('startMonth', this.startDateMonth);
            localStorage.setItem('endMonth', this.endDateMonth);
            localStorage.setItem('city', this.city_);
            localStorage.setItem('country', this.countryLocal);
            localStorage.setItem('state', this.stateSelected_);
            localStorage.setItem('currency', this.currency);
            window.location.href = '/details/hotel';
        },
        async getMonths() {
            this.startDateMonth = "";
            this.monthsavail = "";
            this.endDateMonth = [];
            this.endMonthOptions = [];
            this.loading = true;
            let country = this.countryLocal;
            let state = this.stateSelected_;
            let city = this.city_;
            let response = await axios.get('/api/getMonths', { params: { country, state, city } });
            this.monthsavail = response.data;
            this.loading = false;
        },
        viewModal: function(resortId) {
            document.getElementById("modal" + resortId).style.display = "table";
            //this.showModal = true;
        },
        async viewModalLater(resortId) {
            let response = await axios.get('/api/getImages', { params: { resortId } });
            this.images = response.data;
            document.getElementById("modalLater" + resortId).style.display = "table";
            //this.showModal = true;
        },
        async viewModalLaterMap(resortId) {
            document.getElementById("modalLaterMap" + resortId).style.display = "table";
            //this.showModal = true;
        },
        seeFiltros: function() {
            if (this.filtros == true) {
                this.filtros = false;
            } else {
                this.filtros = true;
            }
        },
        seeBuscador: function() {
            if (this.buscador == true) {
                this.buscador = false;
            } else {
                this.buscador = true;
            }
        },
        closeModal: function(resortId) {
            //this.showModal = false;
            document.getElementById("modal" + resortId).style.display = "none";
        },
        closeModalLater: function(resortId) {
            this.showModal = false;
            document.getElementById("modalLater" + resortId).style.display = "none";
        },
        closeModalLaterMap: function(resortId) {
            //this.showModal = false;
            document.getElementById("modalLaterMap" + resortId).style.display = "none";
        },
        seeMore: function(hotelIdResort, invType, price) {
            document.getElementById("div" + invType + hotelIdResort + price).style.display = "block";
            document.getElementById("less" + invType + hotelIdResort + price).style.display = "block";
            document.getElementById("more" + invType + hotelIdResort + price).style.display = "none";
        },
        seeLess: function(hotelIdResort, invType, price) {
            document.getElementById("div" + invType + hotelIdResort + price).style.display = "none";
            document.getElementById("less" + invType + hotelIdResort + price).style.display = "none";
            document.getElementById("more" + invType + hotelIdResort + price).style.display = "block";
        },
        seeMoreR: function(hotelIdResort, invType, price) {
            document.getElementById("divR" + invType + hotelIdResort + price).style.display = "block";
            document.getElementById("lessR" + invType + hotelIdResort + price).style.display = "block";
            document.getElementById("moreR" + invType + hotelIdResort + price).style.display = "none";
        },
        seeLessR: function(hotelIdResort, invType, price) {
            document.getElementById("divR" + invType + hotelIdResort + price).style.display = "none";
            document.getElementById("lessR" + invType + hotelIdResort + price).style.display = "none";
            document.getElementById("moreR" + invType + hotelIdResort + price).style.display = "block";
        },
        selectedWeeks(week) {
            if (document.getElementById(week).checked) {
                this.weeksSelected = week;
            } else {
                this.weeksSelected = null;
            }
        },
        selectedOccupancy(oc) {
            if (document.getElementById(oc).checked) {
                this.occupancySelected = oc;
            } else {
                this.occupancySelected = null;
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        addLeadingZeros(number, targetLength) {
            var sign = number < 0 ? '-' : ''
            var output = Math.abs(number).toString()
            while (output.length < targetLength) {
                output = '0' + output
            }
            return sign + output
        },

        fnformatDate(value) {
            var months = ['', "Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var [year, month, day] = value.split('-');
            day = day.split(' ');
            var dd = this.addLeadingZeros(day[0], 2) + " / " + months[parseInt(month)] + " / " + this.addLeadingZeros(year, 4);
            return dd;
        },
        parsetext(value) {
            if (value) {
                var datetext = this.months.find(m => m.month === value);
                return datetext.name + ' ' + datetext.year;
            }
        }
    },
    computed: {
        startMonthOptions() {
            return this.months.map(m => {
                return {
                    value: m.month,
                    text: `${m.name} ${m.year}`
                };
            })
        },
        hotelListFiltered() {
            let filtered = this.hotels;
            if (this.resortName !== null && this.resortName != "Resort") {
                let re = this.resortName;
                filtered = filtered.filter(h => h.ResortName == re);
            } else //if(this.resortName == "Resort")
            {
                filtered = this.hotels;
            }

            if (this.maxOcuppancySelected !== null && this.maxOcuppancySelected !== 'all') {
                filtered = filtered.filter(h => h.MaxOccupancy[0] == this.maxOcuppancySelected);
            } else //if(this.maxOcuppancySelected == 'all')
            {
                let filtered = this.hotels;
            }

            if (this.resortName !== null && this.resortName != "Resort" && this.maxOcuppancySelected !== null && this.maxOcuppancySelected !== 'all') {
                filtered = filtered.filter(h => h.MaxOccupancy[0] == this.maxOcuppancySelected);
                let re = this.resortName;
                filtered = filtered.filter(h => h.ResortName == re);
            }

            if(this.citySelected !== null){
                if(this.citySelected == 'All cities'){
                    let filtered = this.hotels;       
                }else{
                    filtered = filtered.filter(h => h.ResortCity == this.citySelected);
                }
            }

            if (this.weeksSelected !== null) {
                filtered = filtered.filter(h => h.InvTypeInDetail == this.weeksSelected);
            }

            if (this.occupancySelected !== null) {
                filtered = filtered.filter(h => h.MaxOccupancy == this.occupancySelected);
            }
            if (this.priceResort > 0) {
                filtered = filtered.filter(h => h.price <= this.priceResort);
            }

            return filtered;
        },
        color() {
            if (this.currentSubscription === null) {
                localStorage.setItem('currentSubscription', '');
                localStorage.setItem('typediscountmembership', '');
                return '#AAC90B';
            } else {
                localStorage.setItem('currentSubscription', JSON.stringify(this.currentSubscription.membership_type));
                localStorage.setItem('typediscountmembership', JSON.stringify(this.typediscountmembership));
            }
            return this.currentSubscription.membership_type.bg_color;
        },
        customStyle() {
            return {
                border: `1px solid ${this.color}`,
                color: this.color,
                backgroundColor: 'white'
            };
        },
        customStyleLabel() {
            return {
                color: 'white',
                position: 'absolute',
                margintop: '-1px',
                marginLeft: '70%'
            };
        },
    },
    watch: {}
}
</script>
<style>
#searchButton {
    background-color: #016799 !important;
    color: #fff;
}

.customColor21 {
    background-color: #474C52;
    color: #fff;
}
.minus8 {
    display: none;
    overflow-y: auto;
}

.more8 {
    display: none;
    max-height: 435px !important;
    height: 435px !important;
    overflow-y: auto;
}

.customColor210 {
    background-color: #C4C4C4;
    color: #fff;
}

/*.relevant-label::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    bottom: 0px;
    border-left: 2.5rem solid transparent;
    border-right: 2.5rem solid transparent;
    border-bottom: 1rem solid white;
}*/
.tablemore {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    overflow-y: auto;
}

.tableminus {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-height: 435px !important;
    height: 435px !important;
    overflow-y: auto;
}
.avg-label {
    width: 5rem;
    height: 4.5rem;
    font-size: 1rem;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 70%;
}

.avg-labeles {
    width: 6rem;
    height: 4.5rem;
    font-size: 1rem;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 70%;
}

.no-hover:hover {}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .05);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 70%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}
.ccolor {
    background-color: #E5E5E5;
    text-align: center;
    border-bottom: 1px solid #045882;
    border-top: 1px solid #045882;
    color: #565656
}

.descolor {
    text-align: center;
    border-bottom: 1px solid #045882;
    border-top: 1px solid #045882;
    color: #565656
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
